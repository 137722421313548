import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

function Impressum (props) {
  function List (props) {
    return (
      <ul className={'mt-0'} type={'1'}>{props.children}</ul>
    );
  }
  function renderLink (props) {
    return (<a href={props.href} target={'_blank'}>{props.children}</a>);
  }

  return (
    <Layout title={'Franziska Schittler Impressum'}>
      <SEO title="Franziska Schittler Impressum"/>
      <section className="section pb-0">
        <div className={'columns'}>
          <div className={'column is-10 is-offset-1 pl-0'}>
            <h1 className="title">{'Impressum'}</h1>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="columns is-multiline ">

          <div className={'column is-10 is-offset-1'}>
            <div className="columns is-multiline is-vcentered has-border-bottom has-padding-bottom is-between is-flex">
              <h3 className={'title is-dark-snd is-inline-block is-marginless'}>{'Informationspflicht laut § 5 TMG.'}</h3>
            </div>
            <div className="columns ">
              <div className="column is-12" style={{ paddingTop: 16, paddingLeft: 0 }}>
                <p className="is-text is-raleway">
                  Franziska Schittler
                </p>
                <p className="is-text is-raleway">
                  Franziska Schittler Restaurierung
                </p>
                <p className="is-text is-raleway">
                  Drosselweg 18
                </p>
                <p className="is-text is-raleway">
                  82152 Krailling
                </p>
              </div>
            </div>
          </div>

          <div className={'column is-10 is-offset-1'} style={{ marginTop: 32 }}>
            <div className="columns is-multiline is-vcentered has-padding-bottom is-between is-flex">
              <h3 className={'title is-dark-snd is-inline-block is-marginless'}>{'Kontakt:'}</h3>
            </div>
            <div className="columns ">
              <div className="column is-12" style={{ paddingTop: 16, paddingLeft: 0 }}>
                <p className="is-text is-raleway">
                  Telefon: +49 172 8682725
                </p>
                <p className="is-text is-raleway">
                  E-Mail: schittler.restaurierung@gmail.com
                </p>
              </div>
            </div>
          </div>

          <div className={'column is-10 is-offset-1 has-border-bottom'} style={{ marginTop: 32 }}>
            <div className="columns is-multiline is-vcentered is-between is-flex ">
              <h3 className={'title is-dark-snd is-inline-block is-marginless'}>{'Umsatzsteuer-ID:'}</h3>
            </div>
            <div className="columns ">
              <div className="column is-12" style={{ paddingTop: 16, paddingLeft: 0 }}>
                <p className="is-text is-raleway ">
                  Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
                </p>
              </div>
            </div>
          </div>

          <div className={'column is-10 is-offset-1 has-border-bottom'} style={{ marginTop: 32 }}>
            <div className="columns is-multiline is-vcentered is-between is-flex ">
              <h3 className={'title is-dark-snd is-inline-block is-marginless'}>{'Fotografie:'}</h3>
            </div>
            <div className="columns ">
              <div className="column is-12" style={{ paddingTop: 16, paddingLeft: 0 }}>
                <p className="is-text is-raleway ">
                  © Johannes Schittler - München
                  <br/>
                  © Franziska Schittler - München
                </p>
              </div>
            </div>
          </div>

          <div className={'column is-10 is-offset-1 has-border-bottom'} style={{ marginTop: 32 }}>
            <div className="columns is-multiline is-vcentered is-between is-flex ">
              <h3 className={'title is-dark-snd is-inline-block is-marginless'}>{'Programmierung & Layout:'}</h3>
            </div>
            <div className="columns ">
              <div className="column is-12" style={{ paddingTop: 16, paddingLeft: 0 }}>
                <p className="is-text is-raleway ">
                  Falk Michél
                </p>
                <a className={'is-link'} target={'_blank'} href={'https://falkmichel.com'}>falkmichel.com</a>
              </div>
            </div>
          </div>

          <div className={'column is-10 is-offset-1'} style={{ marginTop: 32 }}>
            <div className="columns is-multiline is-vcentered is-between is-flex ">
              <h3 className={'title is-dark-snd is-inline-block is-marginless'}>{'Informationspflicht laut § 5 TMG.:'}</h3>
            </div>
            <div className="columns">
              <div className="column is-12" style={{ paddingTop: 16, paddingLeft: 0 }}>
                <p className="is-text subtitle is-raleway">
                  EU-Streitschlichtung
                </p>
                <p className="is-text is-raleway ">
                  Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.
                  Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen Kommission unter http://ec.europa.eu/odr?tid=321196004 zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.
                  Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                </p>
              </div>
            </div>
            <div className="columns">
              <div className="column is-12" style={{ paddingTop: 16, paddingLeft: 0 }}>
                <p className="is-text subtitle is-raleway">
                  Haftung für Inhalte dieser Website
                </p>
                <p className="is-text is-raleway ">
                  Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle Informationen bereitzustellen. Laut Telemediengesetz (TMG) §7 (1) sind wir als Diensteanbieter für eigene Informationen, die wir zur Nutzung bereitstellen, nach den allgemeinen Gesetzen verantwortlich. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser Webseite übernehmen, speziell für jene die seitens Dritter bereitgestellt wurden. Als Diensteanbieter im Sinne der §§ 8 bis 10 sind wir nicht verpflichtet, die von ihnen übermittelten oder gespeicherten Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                  Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen aufgrund von gerichtlichen oder behördlichen Anordnungen bleiben auch im Falle unserer Nichtverantwortlichkeit nach den §§ 8 bis 10 unberührt.
                  Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend zu kontaktieren, damit wir die rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten im Impressum.
                </p>
              </div>
            </div>
            <div className="columns">
              <div className="column is-12" style={{ paddingTop: 16, paddingLeft: 0 }}>
                <p className="is-text subtitle is-raleway">
                  Haftung für Links auf dieser Website
                </p>
                <p className="is-text is-raleway ">
                  Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich sind. Haftung für verlinkte Websites besteht für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.
                  Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir Sie uns zu kontaktieren. Sie finden die Kontaktdaten im Impressum.
                </p>
              </div>
            </div>

            <div className="columns">
              <div className="column is-12" style={{ paddingTop: 16, paddingLeft: 0 }}>
                <p className="is-text subtitle is-raleway">
                  Urheberrechtshinweis
                </p>
                <p className="is-text is-raleway ">
                  Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht der Bundesrepublik Deutschland. Bitte fragen Sie uns bevor Sie die Inhalte dieser Website verbreiten, vervielfältigen oder verwerten wie zum Beispiel auf anderen Websites erneut veröffentlichen. Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.
                  Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu kontaktieren.                </p>
              </div>
            </div>

            <div className="columns">
              <div className="column is-12" style={{ paddingTop: 16, paddingLeft: 0 }}>
                <p className="is-text subtitle is-raleway">
                  Bildernachweis
                </p>
                <p className="is-text is-raleway ">
                  Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.
                  Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:
                  Franziska Schittler
                </p>
              </div>
            </div>
            <br/>
            <div className="columns">
              <h1 className={'title pl-0 ml-0'}>Datenschutzerklärung</h1>
            </div>
            <div className="columns">
              <div className="column is-12" style={{ paddingTop: 16, paddingLeft: 0 }}>
                <p className="is-text subtitle is-raleway">
                  Datenschutz
                </p>
                <p className="is-text is-raleway ">
                  Wir haben diese Datenschutzerklärung (Fassung 19.07.2020-321196004) verfasst, um Ihnen gemäß der Vorgaben der Datenschutz-Grundverordnung (EU) 2016/679 zu erklären, welche Informationen wir sammeln, wie wir Daten verwenden und welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.
                  Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben uns bei der Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar wie möglich zu beschreiben.
                </p>
              </div>
            </div>

            <div className="columns">
              <div className="column is-12" style={{ paddingTop: 16, paddingLeft: 0 }}>
                <p className="is-text subtitle is-raleway">
                  Speicherung persönlicher Daten
                </p>
                <p className="is-text is-raleway ">
                  Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder andere persönlichen Angaben im Rahmen der Übermittlung eines Formulars oder Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt und der IP-Adresse nur zum jeweils angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte weitergegeben.
                  Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern, die Kontakt ausdrücklich wünschen und für die Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.
                  Wenn Sie uns persönliche Daten per E-Mail schicken – somit abseits dieser Webseite – können wir keine sichere Übertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten niemals unverschlüsselt per E-Mail zu übermitteln.
                  Die Rechtsgrundlage besteht nach Artikel 6  Absatz 1 a DSGVO (Rechtmäßigkeit der Verarbeitung) darin, dass Sie uns die Einwilligung zur Verarbeitung der von Ihnen eingegebenen Daten geben. Sie können diesen Einwilligung jederzeit widerrufen – eine formlose E-Mail reicht aus, Sie finden unsere Kontaktdaten im Impressum.                </p>
              </div>
            </div>

            <div className="columns">
              <div className="column is-12" style={{ paddingTop: 16, paddingLeft: 0 }}>
                <p className="is-text subtitle is-raleway">
                  Rechte laut Datenschutzgrundverordnung
                </p>
                <p className="is-text is-raleway ">
                  Ihnen stehen laut den Bestimmungen der DSGVO grundsätzlich die folgende Rechte zu:
                </p>
                <br/>
                <ol type="i" className={'pl-6'}>
                  <li className={''}>
                    Recht auf Berichtigung (Artikel 16 DSGVO)
                  </li>
                  <li className={'list-item'}>
                    Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)
                  </li>
                  <li className={'list-item'}>
                  Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)
                  </li>
                  <li className={'list-item'}>
                  Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)
                  </li>
                  <li className={'list-item'}>
                  Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)
                  </li>
                  <li className={'list-item'}>
                  Widerspruchsrecht (Artikel 21 DSGVO)
                  </li>
                </ol>
                <br/>
                <p>
                  Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)
                  Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich an die Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI) wenden.
                </p>
              </div>
            </div>

            <div className="columns">
              <div className="column is-12" style={{ paddingTop: 16, paddingLeft: 0 }}>
                <p className="is-text subtitle is-raleway">
                  TLS-Verschlüsselung mit https
                </p>
                <p className="is-text is-raleway ">
                  Wir verwenden https um Daten abhörsicher im Internet zu übertragen (Datenschutz durch Technikgestaltung Artikel 25 Absatz 1 DSGVO). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet können wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schloßsymbol links oben im Browser und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.
                </p>
              </div>
            </div>

            <div className="columns">
              <div className="column is-12" style={{ paddingTop: 16, paddingLeft: 0 }}>
                <p className="is-text subtitle is-raleway">
                  Google Maps Datenschutzerklärung
                </p>
                <p className="is-text is-raleway ">
                  Wir benützen auf unserer Website Google Maps der Firma Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich. Mit Google Maps können wir Ihnen Standorte besser zeigen und damit unser Service an Ihre Bedürfnisse anpassen. Durch die Verwendung von Google Maps werden Daten an Google übertragen und auf den Google-Servern gespeichert. Hier wollen wir nun genauer darauf eingehen, was Google Maps ist, warum wir diesen Google-Dienst in Anspruch nehmen, welche Daten gespeichert werden und wie Sie dies unterbinden können.                </p>
              </div>
            </div>

            <div className="columns">
              <div className="column is-12" style={{ paddingTop: 16, paddingLeft: 0 }}>
                <p className="is-text subtitle is-raleway">
                  Was ist Google Maps?
                </p>
                <p className="is-text is-raleway ">
                  Google Maps ist ein Internet-Kartendienst der Firma Google. Mit Google Maps können Sie online über einen PC, ein Tablet oder eine App genaue Standorte von Städten, Sehenswürdigkeiten, Unterkünften oder Unternehmen suchen. Wenn Unternehmen auf Google My Business vertreten sind, werden neben dem Standort noch weitere Informationen über die Firma angezeigt. Um die Anfahrtsmöglichkeit anzuzeigen, können Kartenausschnitte eines Standorts mittels HTML-Code in eine Website eingebunden werden. Google Maps zeigt die Erdoberfläche als Straßenkarte oder als Luft- bzw. Satellitenbild. Dank der Street View Bilder und den hochwertigen Satellitenbildern sind sehr genaue Darstellungen möglich.
                </p>
              </div>
            </div>

            <div className="columns">
              <div className="column is-12" style={{ paddingTop: 16, paddingLeft: 0 }}>
                <p className="is-text subtitle is-raleway">
                  Warum verwenden wir Google Maps auf unserer Webseite?
                </p>
                <p className="is-text is-raleway ">
                  All unsere Bemühungen auf dieser Seite verfolgen das Ziel, Ihnen eine nützliche und sinnvolle Zeit auf unserer Webseite zu bieten. Durch die Einbindung von Google Maps können wir Ihnen die wichtigsten Informationen zu diversen Standorten liefern. Sie sehen auf einen Blick wo wir unseren Firmensitz haben. Die Wegbeschreibung zeigt Ihnen immer den besten bzw. schnellsten Weg zu uns. Sie können den Anfahrtsweg für Routen mit dem Auto, mit öffentlichen Verkehrsmitteln, zu Fuß oder mit dem Fahrrad abrufen. Für uns ist die Bereitstellung von Google Maps Teil unseres Kundenservice.
                </p>
              </div>
            </div>
            <div className="columns">
              <div className="column is-12" style={{ paddingTop: 16, paddingLeft: 0 }}>
                <p className="is-text subtitle is-raleway">
                  Welche Daten werden von Google Maps gespeichert?
                </p>
                <p className="is-text is-raleway ">
                  Damit Google Maps ihren Dienst vollständig anbieten kann, muss das Unternehmen Daten von Ihnen aufnehmen und speichern. Dazu zählen unter anderem die eingegebenen Suchbegriffe, Ihre IP-Adresse und auch die Breiten- bzw. Längenkoordinaten. Benutzen Sie die Routenplaner-Funktion wird auch die eingegebene Startadresse gespeichert. Diese Datenspeicherung passiert allerdings auf den Webseiten von Google Maps. Wir können Sie darüber nur informieren, aber keinen Einfluss nehmen. Da wir Google Maps in unsere Webseite eingebunden haben, setzt Google mindestens ein Cookie (Name: NID) in Ihrem Browser. Dieses Cookie speichert Daten über Ihr Userverhalten. Google nutzt diese Daten in erster Linie, um eigene Dienste zu optimieren und individuelle, personalisierte Werbung für Sie bereitzustellen.
                  <br/>
                  Folgendes Cookie wird aufgrund der Einbindung von Google Maps in Ihrem Browser gesetzt:
                  <br/>
                  Name: NID
                  Wert: 188=h26c1Ktha7fCQTx8rXgLyATyITJ321196004-5
                  Verwendungszweck: NID wird von Google verwendet, um Werbeanzeigen an Ihre Google-Suche anzupassen. Mit Hilfe des Cookies „erinnert“ sich Google an Ihre am häufigsten eingegebenen Suchanfragen oder Ihre frühere Interaktion mit Anzeigen. So bekommen Sie immer maßgeschneiderte Werbeanzeigen. Das Cookie enthält eine einzigartige ID, die Google benutzt, um Ihre persönlichen Einstellungen für Werbezwecke zu sammeln.
                  Ablaufdatum: nach 6 Monaten
                  <br/>
                  Anmerkung: Wir können bei den Angaben der gespeicherten Daten keine Vollständigkeit gewährleisten. Speziell bei der Verwendung von Cookies sind Veränderungen nie auszuschließen. Um das Cookie NID zu identifizieren, wurde eine eigene Testseite angelegt, wo ausschließlich Google Maps eingebunden war.
                </p>
              </div>
            </div>
            <div className="columns">
              <div className="column is-12" style={{ paddingTop: 16, paddingLeft: 0 }}>
                <p className="is-text subtitle is-raleway">
                  Wie lange und wo werden die Daten gespeichert?
                </p>
                <p className="is-text is-raleway ">
                  Die Google-Server stehen in Rechenzentren auf der ganzen Welt. Die meisten Server befinden sich allerdings in Amerika. Aus diesem Grund werden Ihre Daten auch vermehrt in den USA gespeichert. Hier können Sie genau nachlesen wo sich die Google-Rechenzentren befinden: https://www.google.com/about/datacenters/inside/locations/?hl=de
                  Die Daten verteilt Google auf verschiedenen Datenträgern. Dadurch sind die Daten schneller abrufbar und werden vor etwaigen Manipulationsversuchen besser geschützt. Jedes Rechenzentrum hat auch spezielle Notfallprogramme. Wenn es zum Beispiel Probleme bei der Google-Hardware gibt oder eine Naturkatastrophe die Server lahm legt, bleiben die Daten ziemlich sicher trotzdem geschützt.
                  Manche Daten speichert Google für einen festgelegten Zeitraum. Bei anderen Daten bietet Google lediglich die Möglichkeit, diese manuell zu löschen. Weiters anonymisiert das Unternehmen auch Informationen (wie zum Beispiel Werbedaten) in Serverprotokollen, indem es einen Teil der IP-Adresse und Cookie-Informationen nach 9 bzw.18 Monaten löscht.
                </p>
              </div>
            </div>
            <div className="columns">
              <div className="column is-12" style={{ paddingTop: 16, paddingLeft: 0 }}>
                <p className="is-text subtitle is-raleway">
                  Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
                </p>
                <p className="is-text is-raleway ">
                  Mit der 2019 eingeführten automatischen Löschfunktion von Standort- und Aktivitätsdaten werden Informationen zur Standortbestimmung und Web-/App-Aktivität – abhängig von Ihrer Entscheidung – entweder 3 oder 18 Monate gespeichert und dann gelöscht. Zudem kann man diese Daten über das Google-Konto auch jederzeit manuell aus dem Verlauf löschen. Wenn Sie Ihre Standorterfassung vollständig verhindern wollen, müssen Sie im Google-Konto die Rubrik „Web- und App-Aktivität“ pausieren. Klicken Sie „Daten und Personalisierung“ und dann auf die Option „Aktivitätseinstellung“. Hier können Sie die Aktivitäten ein- oder ausschalten.
                  In Ihrem Browser können Sie weiters auch einzelne Cookies deaktivieren, löschen oder verwalten. Je nach dem welchen Browser Sie verwenden, funktioniert dies immer etwas anders. Die folgenden Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:
                  Chrome: Cookies in Chrome löschen, aktivieren und verwalten
                  Safari: Verwalten von Cookies und Websitedaten mit Safari
                  Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben
                  Internet Explorer: Löschen und Verwalten von Cookies
                  Microsoft Edge: Löschen und Verwalten von Cookies
                  Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie es erlauben oder nicht.
                  Google ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der korrekte und sichere Datentransfer persönlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI. Wenn Sie mehr über die Datenverarbeitung von Google erfahren wollen, empfehlen wir Ihnen die hauseigene Datenschutzerklärung des Unternehmens unter https://policies.google.com/privacy?hl=de.                </p>
              </div>
            </div>
            <div className="columns">
              <div className="column is-12" style={{ paddingTop: 16, paddingLeft: 0 }}>
                <p className="is-text subtitle is-raleway">
                  Google Fonts Datenschutzerklärung
                </p>
                <p className="is-text is-raleway ">
                  Auf unserer Website verwenden wir Google Fonts. Das sind die “Google-Schriften” der Firma Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich.
                  Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein Passwort hinterlegen. Weiters werden auch keine Cookies in Ihrem Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts) werden über die Google-Domains fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google sind die Anfragen nach CSS und Schriften vollkommen getrennt von allen anderen Google-Diensten. Wenn Sie ein Google-Konto haben, brauchen Sie keine Sorge haben, dass Ihre Google-Kontodaten, während der Verwendung von Google Fonts, an Google übermittelt werden. Google erfasst die Nutzung von CSS (Cascading Style Sheets) und der verwendeten Schriftarten und speichert diese Daten sicher. Wie die Datenspeicherung genau aussieht, werden wir uns noch im Detail ansehen.
                </p>
              </div>
            </div>

            <div className="columns">
              <div className="column is-12" style={{ paddingTop: 16, paddingLeft: 0 }}>
                <p className="is-text subtitle is-raleway">
                  Was sind Google Fonts?
                </p>
                <p className="is-text is-raleway ">
                  Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über 800 Schriftarten, die Google seinen Nutzern kostenlos zu Verfügung stellt.
                  Viele dieser Schriftarten sind unter der SIL Open Font License veröffentlicht, während andere unter der Apache-Lizenz veröffentlicht wurden. Beides sind freie Software-Lizenzen.
                </p>
              </div>
            </div>

            <div className="columns">
              <div className="column is-12" style={{ paddingTop: 16, paddingLeft: 0 }}>
                <p className="is-text subtitle is-raleway">
                  Warum verwenden wir Google Fonts auf unserer Webseite?
                </p>
                <p className="is-text is-raleway ">
                  Mit Google Fonts können wir auf unserer eigenen Webseite Schriften nutzen, doch müssen sie nicht auf unseren eigenen Server hochladen. Google Fonts ist ein wichtiger Baustein, um die Qualität unserer Webseite hoch zu halten. Alle Google-Schriften sind automatisch für das Web optimiert und dies spart Datenvolumen und ist speziell für die Verwendung mit mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere Seite besuchen, sorgt die niedrige Dateigröße für eine schnelle Ladezeit. Des Weiteren sind Google Fonts sichere Web Fonts. Unterschiedliche Bildsynthese-Systeme (Rendering) in verschiedenen Browsern, Betriebssystemen und mobilen Endgeräten können zu Fehlern führen. Solche Fehler können teilweise Texte bzw. ganze Webseiten optisch verzerren. Dank des schnellen Content Delivery Networks (CDN) gibt es mit Google Fonts keine plattformübergreifenden Probleme. Google Fonts unterstützt alle gängigen Browser (Google Chrome, Mozilla Firefox, Apple Safari, Opera) und funktioniert zuverlässig auf den meisten modernen mobilen Betriebssystemen, einschließlich Android 2.2+ und iOS 4.2+ (iPhone, iPad, iPod). Wir verwenden die Google Fonts also, damit wir unser gesamtes Online-Service so schön und einheitlich wie möglich darstellen können.
                </p>
              </div>
            </div>

            <div className="columns">
              <div className="column is-12" style={{ paddingTop: 16, paddingLeft: 0 }}>
                <p className="is-text subtitle is-raleway">
                  Welche Daten werden von Google gespeichert?
                </p>
                <p className="is-text is-raleway ">
                  Wenn Sie unsere Webseite besuchen, werden die Schriften über einen Google-Server nachgeladen. Durch diesen externen Aufruf werden Daten an die Google-Server übermittelt. So erkennt Google auch, dass Sie bzw. Ihre IP-Adresse unsere Webseite besuchen. Die Google Fonts API wurde entwickelt, um Verwendung, Speicherung und Erfassung von Endnutzerdaten auf das zu reduzieren, was für eine ordentliche Bereitstellung von Schriften nötig ist. API steht übrigens für „Application Programming Interface“ und dient unter anderem als Datenübermittler im Softwarebereich.
                  Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google und ist somit geschützt. Durch die gesammelten Nutzungszahlen kann Google feststellen, wie gut die einzelnen Schriften ankommen. Die Ergebnisse veröffentlicht Google auf internen Analyseseiten, wie beispielsweise Google Analytics. Zudem verwendet Google auch Daten des eigenen Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften verwenden. Diese Daten werden in der BigQuery-Datenbank von Google Fonts veröffentlicht. Unternehmer und Entwickler nützen das Google-Webservice BigQuery, um große Datenmengen untersuchen und bewegen zu können.
                  Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen wie Spracheinstellungen, IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers und Name des Browsers automatisch an die Google-Server übertragen werden. Ob diese Daten auch gespeichert werden, ist nicht klar feststellbar bzw. wird von Google nicht eindeutig kommuniziert.                </p>
              </div>
            </div>

            <div className="columns">
              <div className="column is-12" style={{ paddingTop: 16, paddingLeft: 0 }}>
                <p className="is-text subtitle is-raleway">
                  Wie lange und wo werden die Daten gespeichert?
                </p>
                <p className="is-text is-raleway ">
                  Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen Servern, die hauptsächlich außerhalb der EU angesiedelt sind. Das ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu nutzen. Ein Stylesheet ist eine Formatvorlage, über die man einfach und schnell z.B. das Design bzw. die Schriftart einer Webseite ändern kann.
                  Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel, die Ladezeit von Webseiten grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften verweisen, werden sie nach dem ersten Besuch zwischengespeichert und erscheinen sofort auf allen anderen später besuchten Webseiten wieder. Manchmal aktualisiert Google Schriftdateien, um die Dateigröße zu reduzieren, die Abdeckung von Sprache zu erhöhen und das Design zu verbessern.
                </p>
              </div>
            </div>

            <div className="columns">
              <div className="column is-12" style={{ paddingTop: 16, paddingLeft: 0 }}>
                <p className="is-text subtitle is-raleway">
                  Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
                </p>
                <p className="is-text is-raleway ">
                  Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht einfach gelöscht werden. Die Daten werden beim Seitenaufruf automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu können, müssen Sie den Google-Support auf https://support.google.com/?hl=de&tid=321196004 kontaktieren. Datenspeicherung verhindern Sie in diesem Fall nur, wenn Sie unsere Seite nicht besuchen.
                  Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir können also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf https://developers.google.com/fonts/faq?tid=321196004. Dort geht zwar Google auf datenschutzrelevante Angelegenheiten ein, doch wirklich detaillierte Informationen über Datenspeicherung sind nicht enthalten. Es ist relativ schwierig, von Google wirklich präzise Informationen über gespeicherten Daten zu bekommen.
                  Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten verwendet werden, können Sie auch auf https://www.google.com/intl/de/policies/privacy/ nachlesen.                </p>
                <p className="is-text subtitle is-raleway">
                  <br/>
                  Quelle: Erstellt mit dem Datenschutz Generator von&nbsp;
                  <a href={'https://www.adsimple.de/datenschutz-generator/'} target={'_blank'}>AdSimple.de</a> in Kooperation mit&nbsp;
                  <a href={'https://www.fashiongott.de'} target={'_blank'}>
                    fashiongott.de
                  </a>
                </p>
              </div>
            </div>
          </div>

        </div>
      </section>
    </Layout>
  );
}

export default Impressum;
